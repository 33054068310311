import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from 'ng-select2-component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateModule } from '@ngx-translate/core';

import { ContentComponent } from './components/layout/content/content.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SearchComponent } from './components/header/widgets/search/search.component';
import { LanguagesComponent } from './components/header/widgets/languages/languages.component';
import { NotificationComponent } from './components/header/widgets/notification/notification.component';
import { ModeComponent } from './components/header/widgets/mode/mode.component';
import { ProfileComponent } from './components/header/widgets/profile/profile.component';

import { AlertComponent } from './components/ui/alert/alert.component';
import { ButtonComponent } from './components/ui/button/button.component';
import { ConfirmationModalComponent } from './components/ui/modal/confirmation-modal/confirmation-modal.component';
import { PageWrapperComponent } from './components/page-wrapper/page-wrapper.component';
import { TableComponent } from './components/ui/table/table.component';
import { DeleteModalComponent } from './components/ui/modal/delete-modal/delete-modal.component';
import { PaginationComponent } from './components/ui/pagination/pagination.component';
import { FormFieldsComponent } from './components/ui/form-fields/form-fields.component';
import { MediaModalComponent } from './components/ui/modal/media-modal/media-modal.component';
import { MediaBoxComponent } from './components/ui/media-box/media-box.component';
import { NoDataComponent } from './components/ui/no-data/no-data.component';
import { ImageUploadComponent } from './components/ui/image-upload/image-upload.component';
import { AdvancedDropdownComponent } from './components/ui/advanced-dropdown/advanced-dropdown.component';
import { DropdownListComponent } from './components/ui/advanced-dropdown/dropdown-list/dropdown-list.component';
import { PayoutModalComponent } from './components/ui/modal/payout-modal/payout-modal.component';
import { ImportCsvModalComponent } from './components/ui/modal/import-csv-modal/import-csv-modal.component';
import { ProductBoxComponent } from './components/ui/product-box/product-box.component';
import { AddtocartComponent } from './components/ui/product-box/modal/addtocart/addtocart.component';
import { ProductBoxSkeletonComponent } from './components/ui/skeleton/product-box-skeleton/product-box-skeleton.component';
import { SidebarMenuSkeletonComponent } from './components/ui/skeleton/sidebar-menu-skeleton/sidebar-menu-skeleton.component';
import { CounterComponent } from './components/ui/counter/counter.component';
import { AlertModalComponent } from './components/ui/modal/alert-modal/alert-modal.component';
import { FileUploadComponent } from './components/ui/file-upload/file-upload.component';
import { LoaderComponent } from './components/ui/loader/loader.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';

// Directives
import { ClickOutsideDirective } from './directive/out-side-directive';
import { NumberDirective } from './directive/numbers-only.directive';
import { HasPermissionDirective } from './directive/has-permission.directive';

// Pipes
import { CurrencySymbolPipe } from './pipe/currency-symbol.pipe';
import { SummaryPipe } from './pipe/summary.pipe';
import { FilterPipe } from './pipe/filter.pipe';
import { ExportModalComponent } from './components/ui/modal/export-modal/export-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GiftComponent } from '../components/user/gift/gift.component';
import { WheelComponent } from '../components/user/wheel/wheel.component';

@NgModule({
    declarations: [
        ContentComponent,
        HeaderComponent,
        SidebarComponent,
        LanguagesComponent,
        NotificationComponent,
        ModeComponent,
        ProfileComponent,
        FooterComponent,
        SearchComponent,
        AlertComponent,
        ButtonComponent,
        ConfirmationModalComponent,
        PageWrapperComponent,
        TableComponent,
        DeleteModalComponent,
        PaginationComponent,
        FormFieldsComponent,
        MediaModalComponent,
        MediaBoxComponent,
        NoDataComponent,
        ImageUploadComponent,
        AdvancedDropdownComponent,
        DropdownListComponent,
        PayoutModalComponent,
        ImportCsvModalComponent,
        ProductBoxComponent,
        AddtocartComponent,
        ProductBoxSkeletonComponent,
        SidebarMenuSkeletonComponent,
        CounterComponent,
        AlertModalComponent,
        FileUploadComponent,
        LoaderComponent,
        // Directives
        ClickOutsideDirective,
        NumberDirective,
        HasPermissionDirective,
        ExportModalComponent,
        GiftComponent,
        WheelComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        NgbModule,
        Select2Module,
        NgxDropzoneModule,
        CarouselModule,
        TranslateModule,
        CurrencySymbolPipe,
        SummaryPipe,
        FilterPipe,
        DragDropModule,
        NgJsonEditorModule
    ],
    providers: [CurrencyPipe],
    exports: [
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        Select2Module,
        CarouselModule,
        TranslateModule,
        AlertComponent,
        ButtonComponent,
        ConfirmationModalComponent,
        PageWrapperComponent,
        TableComponent,
        DeleteModalComponent,
        PaginationComponent,
        FormFieldsComponent,
        MediaModalComponent,
        MediaBoxComponent,
        NoDataComponent,
        ImageUploadComponent,
        AdvancedDropdownComponent,
        DropdownListComponent,
        PayoutModalComponent,
        ImportCsvModalComponent,
        ProductBoxComponent,
        AddtocartComponent,
        ProductBoxSkeletonComponent,
        SidebarMenuSkeletonComponent,
        CounterComponent,
        AlertModalComponent,
        FileUploadComponent,
        LoaderComponent,
        NgJsonEditorModule,
        // Directives
        ClickOutsideDirective,
        NumberDirective,
        HasPermissionDirective,
        // Pipes
        CurrencySymbolPipe,
        SummaryPipe,
        FilterPipe,
        ExportModalComponent,
        GiftComponent,
        WheelComponent,
    ]
})
export class SharedModule { }