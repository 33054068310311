<ng-template #exportModal let-modal>
    <div class="modal-header">
        <h2>{{ title }}</h2>
        <app-button [class]="'btn btn-close'" [spinner]="false" [id]="'media_close_btn'" (click)="modal.dismiss('Cross click')">
            <i class="ri-close-line"></i>
        </app-button>
    </div>
    <div class="modal-body">
        <div>
            <!-- <app-form-fields [labelClass]="'form-label-title col-sm-4 mb-0'" [gridClass]="'col-sm-8 d-flex gap-5'"
                [label]="'Chọn loại file xuất dữ liệu'">
                <div class="form-check-inline">
                    <input class="form-check-input" id="statusRadio_csv" [value]="'csv'" name="postStatus" type="radio" (click)="onFileTypeChange($event)" />
                    <label class="form-check-label ms-1" for="statusRadio_csv">CSV</label>
                </div>
                <div class="form-check-inline">
                    <input class="form-check-input" id="statusRadio_xlsx" [value]="'xlsx'" name="postStatus" type="radio" (click)="onFileTypeChange($event)" />
                    <label class="form-check-label ms-1" for="statusRadio_xlsx">XLSX</label>
                </div>
            </app-form-fields> -->
            <app-form-fields [labelClass]="'form-label-title col-sm-4 mb-0'" [gridClass]="'col-sm-8'"
                [label]="'Tìm field cần xuất dữ liệu'">
                <input class="form-control" id="search" type="text" placeholder="{{ 'Nhập Tên Field' | translate }}" (keyup)="search($event)" />
            </app-form-fields>
            <app-form-fields [labelClass]="'form-label-title col-sm-4 mb-0'" [gridClass]="'col-sm-8 d-flex gap-3'"
                [label]="'Chọn mẫu xuất dữ liệu'">
                <select2 class="custom-select w-100" [placeholder]="'Chọn mẫu xuất dữ liệu' | translate" [value]="selectedTemplate"
                    [data]="selectTemplates.length ? selectTemplates : []" (update)="onSelectTemplate($event)">
                </select2>
                <div *ngIf="selectedTemplate && selectedTemplate != 'new'" class="d-flex align-items-center gap-3">
                    <i class="ri-delete-bin-line ri-icon" [title]="'Xóa mẫu' | translate" (click)="confirmationModal.openModal('open')"></i>
                    <i class="ri-save-line ri-icon" [title]="'Lưu mẫu' | translate" (click)="saveTemplate('update')"></i>
                </div>
                <div *ngIf="selectedTemplate == 'new'" class="d-flex align-items-center gap-3 w-100">
                    <input class="form-control" id="templateName" type="text" placeholder="{{ 'Nhập tên mẫu' | translate }}" (change)="onTemplateNameChange($event)" />
                    <i class="ri-save-line ri-icon" [title]="'Lưu mẫu' | translate" (click)="saveTemplate('create')"></i>
                </div>
            </app-form-fields>
        </div>
        <div class="row justify-content-between">
            <div class="col-sm-6">
                <div class="fs-6 fw-bolder pb-3 d-flex align-items-center gap-3">
                    {{ 'Available fields' | translate}}
                    <button type="button" [title]="'Chọn tất cả' | translate" class="btn btn-outline p-0" style="width: 1.5rem; height: 1.5rem;" (click)="selectAll()">
                        <i class="ri-add-line"></i>
                    </button>
                </div>
                <div class="list-group" style="height: 40vh; overflow-y: auto;">
                    <div
                        *ngFor="let field of filteredFields; let i = index"
                        type="button"
                        class="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-1"
                    >
                        {{field.display}}
                        <i class="ri-add-line px-2 py-1 fs-5" (click)="addField(field)"></i>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="fs-6 fw-bolder pb-3 d-flex align-items-center gap-3">
                    {{ 'Export fields' | translate}}
                    <button type="button" [title]="'Bỏ tất cả' | translate" class="btn btn-outline p-0" style="width: 1.5rem; height: 1.5rem;" (click)="clearAll()">
                        <i class="ri-subtract-line"></i>
                    </button>
                </div>
                <div cdkDropList (cdkDropListDropped)="drop($event)" class="list-group" style="max-height: 40vh; overflow-y: scroll;">
                    <div
                        *ngFor="let field of exportFields; let i = index"
                        cdkDrag
                        type="button"
                        class="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-1"
                    >
                        <div class="d-flex justify-content-between align-items-center gap-3">
                            <i class="ri-expand-up-down-fill"></i>
                            {{field.display}}
                        </div>
                        <i class="ri-delete-bin-5-line px-2 py-1 fs-5" (click)="removeField(field)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="left-part"></div>
        <div class="right-part">
            <app-button 
                [class]="'align-items-center btn btn-theme d-flex'" 
                [id]="'export'"
                (click)="export()">
                {{ 'Export' | translate }}
            </app-button>
        </div>
    </div>
</ng-template>

<app-confirmation-modal
    #confirmationModal
    [title]="'Bạn có chắc chắn muốn xóa mẫu này?' | translate"
    [message]="' ' | translate"
    (confirmed)="deleteTemplate($event)"
></app-confirmation-modal>