import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@/environments/environment";
import { FileTypeValue, ProductImage } from "../interfaces/file.interface";
import { FileType } from "../interfaces/file.interface";

@Injectable({
    providedIn: "root",
})
export class FileService {

    constructor(private http: HttpClient) { }

    convertFileToBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    convertBase64ToBlob(base64Image: string) {
        const parts = base64Image.split(';base64,');
        const imageType = parts[0].split(':')[1];
        const decodedData = window.atob(parts[1]);
        const uInt8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: imageType });
    }

    downloadFile(blob: any, fileName: string) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    upload(files: File[], type: string, productId?: number, userName?: string) {
        const formData = new FormData();
        files.forEach((file: File) => {
            formData.append('File', file, file.name);
            formData.append('FileType', type);
            if (productId) {
                formData.append('ProductId', productId!.toString());
            }
            if (userName) {
                formData.append('UserName', userName!);
            }
        });
        return this.http.post(`${environment.baseURL}upload-file`, formData, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        });
    }

    uploadFileUser(files: File[], type: string, userName?: string, fileName?: string) {
        const formData = new FormData();
        files.forEach((file: File) => {
            formData.append('File', file, file.name);
            formData.append('FileType', type);
            if (userName) {
                formData.append('UserName', userName!);
            }
            if (fileName) {
                formData.append('FileName', fileName!);
            }
        });
        return this.http.post(`${environment.baseURL}upload-file`, formData, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        });
    }

    uploadFiles(files: File[], productId?: number, userName?: string, fileName?: string) {
        const formData = new FormData();
        files.forEach((file: File) => {
            formData.append('File', file, file.name);
            formData.append('FileType', file.type.includes("image/") ? "ProductImage" : "UserDocument");
            if (productId) {
                formData.append('ProductId', productId!.toString());
            }
            if (userName) {
                formData.append('UserName', userName!);
            }
            if (fileName) {
                formData.append('FileName', fileName!);
            }
        });
        return this.http.post(`${environment.baseURL}upload-file`, formData, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        });
    }
    updatePostImageFiles(files: File[]) {
        return this.upload(files, FileTypeValue.PostImage);
    }
    updatePostThumbnailFiles(files: File[]) {
        return this.upload(files, FileTypeValue.PostContent);
    }

    getPostImages() {
        return this.http.get<ProductImage[]>(`${environment.baseURL}get-post-images`);
    }

    getHomeConfigImages() {
        return this.http.get<ProductImage[]>(`${environment.baseURL}get-home-config-images`);
    }

    getSingedUrl(fileType: FileType, fileName: string) {
        return this.http.get(`${environment.baseURL}get-signed-url?fileType=${fileType}&fileName=${fileName}`, { responseType: 'text' });
    }

    getAdminSignedUrl(fileType: FileType, fileName: string, userName: string) {
        return this.http.get(`${environment.baseURL}get-admin-signed-url?fileType=${fileType}&fileName=${fileName}&userName=${userName}`, { responseType: 'text' });
    }

    deleteFile(fileID: string) {
        return this.http.delete(`${environment.baseURL}delete-file?Id=${fileID}`);
    }
}
