import { UserPagingModel } from '@/app/shared/interfaces/user.interface';
import { FileService } from '@/app/shared/services/file.service';
import { ReportService } from '@/app/shared/services/report.service';
import { SharedService } from '@/app/shared/services/shared.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrl: './export-modal.component.scss'
})
export class ExportModalComponent {

  public closeResult: string | undefined;

  @ViewChild("exportModal", { static: false }) exportModal: TemplateRef<any> | undefined;
  @ViewChild("confirmationModal", { static: false }) confirmationModal: ConfirmationModalComponent | undefined;

  @Input() collectionName: string;
  @Input() title: string = "Export";
  @Input() filters: any[] = [];

  @Output() selectedFieldsChange = new EventEmitter<any>();

  public userAction = {};

  tempSelectedFields: any[] = [];
  filteredFields: any[] = [];
  exportFileType: string = 'xlsx';

  availableFields: any[] = [];
  exportFields: any[] = [];
  templates: any[] = [];
  selectTemplates: any[] = [];

  selectedTemplate: string = '';

  templateName: string;

  modalOpen: any;

  isSearching: boolean = false;
  searchKeyword: string = '';

  constructor(
    private modalService: NgbModal,
    private sharedService: SharedService,
    private fileService: FileService,
    private toastService: ToastrService,
    private reportService: ReportService
  ) { }

  getExportTemplates() {
    const body = {
      CollectionName: this.collectionName
    }
    this.reportService.getExportTemplates(body).subscribe({
      next: (res: any) => {
        if (res) {
          this.templates = res;
          this.availableFields = this.templates.find((r: any) => r.isDefaultTemplate).templateFields;

          this.selectTemplates = [];

          this.templates.map((r: any) => {
            this.selectTemplates.push({
              value: r.id,
              label: r.templateName
            });
          });

          this.selectTemplates.push({
            value: 'new',
            label: 'Tạo mới'
          });

          this.filteredFields = this.availableFields.filter((f: any) => !this.exportFields.some(x => x.key === f.key));
          this.sortFields();
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    })
  }

  async openModal(action: string, data?: any, value?: any, title: string = '', message: string = '') {
    if (title) this.title = title;
    this.userAction = {
      actionToPerform: action,
      data: data,
      value: value,
    }
    this.modalService.open(this.exportModal, {
      ariaLabelledBy: 'Confirmation-Modal',
      centered: true,
      windowClass: 'theme-modal modal-xl'
    }).result.then((result) => {
      `Result ${result}`
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.getExportTemplates();
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  search(event: any) {
    this.filteredFields = [];
    this.searchKeyword = event.target.value;
    if (event.target.value) {
      this.filteredFields = this.availableFields.filter((f: any) => !this.exportFields.some(x => x.key === f.key)).filter(f => f.display.toLowerCase().includes(event.target.value.toLowerCase()));
      this.isSearching = true;
    }
    else {
      this.filteredFields = this.availableFields.filter((f: any) => !this.exportFields.some(x => x.key === f.key));
      this.isSearching = false;
    }
    this.sortFields();
  }

  sortFields() {
    this.filteredFields = this.filteredFields.sort((a, b) => a.display.localeCompare(b.display));
  }

  selectAll() {
    if (this.isSearching) {
      this.exportFields = [...new Set([...this.filteredFields, ...this.exportFields].filter(item => item).map(item => item))];
    }
    else {
      this.exportFields = this.availableFields;
    }
    this.filteredFields = [];
  }

  clearAll() {
    if (this.isSearching) {
      this.filteredFields = [...new Set([...this.filteredFields, ...this.exportFields].filter(item => item).map(item => item))].filter(f => f.display.toLowerCase().includes(this.searchKeyword.toLowerCase()));
    }
    else {
      this.filteredFields = [...new Set([...this.availableFields, ...this.exportFields].filter(item => item).map(item => item))];
    }
    this.exportFields = [];
  }

  addField(field: any) {
    if (!this.exportFields.includes(field.key)) {
      this.exportFields.push(field);
    }
    
    this.exportFields = [...new Set([...this.tempSelectedFields, ...this.exportFields].filter(item => item).map(item => item))];
    this.filteredFields = this.availableFields.filter((f: any) => !this.exportFields.some(x => x.key === f.key));
    this.sortFields();
  }

  removeField(field: any) {
    this.exportFields = this.exportFields.filter(f => f.key !== field.key);
    this.filteredFields.push(field);
    this.sortFields();
  }

  drop(event: any) {
    moveItemInArray(this.exportFields, event.previousIndex, event.currentIndex);
  }

  onSelectTemplate(event: any) {
    this.selectedTemplate = event.value;

    if (!this.selectedTemplate || this.selectedTemplate == 'new') {
      return;
    }

    this.exportFields = this.templates.find((r: any) => r.id === event.value).templateFields;
    this.filteredFields = this.availableFields.filter((f: any) => !this.exportFields.some(x => x.key === f.key));
    this.templateName = event.options[0].label;
  }

  onTemplateNameChange(event: any) {
    this.templateName = event.target.value;
  }

  deleteTemplate(event: any) {
    if (event) {
      if (!this.selectedTemplate) {
        this.toastService.warning('Xin chọn mẫu cần xóa!', '', { positionClass: 'toast-top-right' });
        return;
      }
  
      console.log(this.selectedTemplate);
  
      this.reportService.deleteTemplate(this.selectedTemplate).subscribe({
        next: (res: any) => {
          if (res) {
            this.toastService.success('Xóa mẫu thành công!', '', { positionClass: 'toast-top-right' });
            this.exportFields = [];
            this.getExportTemplates();
            this.confirmationModal?.closeModal();
          }
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }
    return;
  }

  saveTemplate(type: string) {
    if (!this.templateName) {
      this.toastService.warning('Xin nhập tên mẫu!', '', { positionClass: 'toast-top-right' });
      return;
    }

    if (this.exportFields.length === 0) {
      this.toastService.warning('Xin chọn trường dữ liệu cần xuất!', '', { positionClass: 'toast-top-right' });
      return;
    }

    let body: any = {
      CollectionName: this.collectionName,
      TemplateName: this.templateName,
      TemplateFields: this.exportFields,
      Filters: this.filters
    }

    switch (type) {
      case 'create':
        this.reportService.createExportTemplate(body).subscribe({
          next: (res: any) => {
            if (res) {
              this.toastService.success('Lưu mẫu thành công!', '', { positionClass: 'toast-top-right' });
              this.getExportTemplates();
            }
          },
          error: (error: any) => {
            console.log(error);
          }
        });
        break;
      case 'update':
        body.id = this.selectedTemplate;
        this.reportService.updateExportTemplate(body).subscribe({
          next: (res: any) => {
            if (res) {
              this.toastService.success('Cập nhật mẫu thành công!', '', { positionClass: 'toast-top-right' });
            }
          },
          error: (error: any) => {
            console.log(error);
          }
        });
        break;
    }
  }

  onFileTypeChange(event: any) {
    console.log(event);
    this.exportFileType = event.target.value;
  }

  export() {
    // if (!this.exportFileType) {
    //   this.toastService.warning('Xin chọn loại file xuất dữ liệu!', '', { positionClass: 'toast-top-right' });
    //   return;
    // }

    if (this.exportFields.length === 0) {
      this.toastService.warning('Xin chọn trường dữ liệu cần xuất!', '', { positionClass: 'toast-top-right' });
      return;
    }

    let body: any = {
      Filters: this.filters,
      TemplateFields: this.exportFields,
      CollectionName: this.collectionName
    }

    this.reportService.exportData(body).subscribe({
      next: (res: any) => {
        if (res) {
          this.fileService.downloadFile(res.body, `${this.collectionName}.${this.exportFileType}`);
          this.toastService.success('Xuất dữ liệu thành công!', '', { positionClass: 'toast-top-right' });
        }
        else {
          this.toastService.error('Xuất dữ liệu thất bại!', '', { positionClass: 'toast-top-right' });
        }
      },
      error: (err: any) => {
        this.toastService.error('Có lỗi xảy ra, vui lòng thử lại sau!', '', { positionClass: 'toast-top-right' });
        console.log(err);
      }
    });
  }

  s2ab(s: any) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
}
