import { BaseResponseModel } from "@/app/shared/interfaces/base-response.interface";
import { Category } from "@/app/shared/interfaces/category.interface";
import { Configuration, Menu } from "@/app/shared/interfaces/configuration.interface";
import { NotificationDto } from "@/app/shared/interfaces/notification.interface";
import { CategoryService } from "@/app/shared/services/category.service";
import { CommonService } from "@/app/shared/services/common.service";
import { ConfigurationService } from "@/app/shared/services/configuration.service";
import { NotificationService } from "@/app/shared/services/notification.service";
import { ProvinceService } from "@/app/shared/services/province.service";
import { PushNotificationService } from "@/app/shared/services/push-notification.service";
import { SharedService } from "@/app/shared/services/shared.service";
import { environment } from "@/environments/environment";
import { Location } from "@angular/common";
import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { MessagePayload, Messaging, onMessage, getToken } from "@angular/fire/messaging"

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent {

  userName = JSON.parse(localStorage.getItem("user")!).username;
  retry: boolean = false;
  private messaging = inject(Messaging);

  constructor(
    public sharedService: SharedService,
    private configurationService: ConfigurationService,
    private commonService: CommonService,
    private categoryService: CategoryService,
    private router: Router,
    public location: Location,
    private provinceService: ProvinceService,
    private pushNotiService: PushNotificationService,
    private notifiService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.sharedService.collapseSidebar = false;

    this.getConfiguration(this.userName);
    this.getCategories();
    this.getSidebar();
    this.getProvincesInfo();

    this.requestToken();
    this.listen();

  }

  getConfiguration(userName?: string) {
    this.configurationService.getConfiguration().subscribe({
      next: (res: Configuration) => {
        if (res.menu) {
          this.commonService.setMenu(res);
        }
        // else {
        //   // create user's configuration for the first time based on common configuration
        //   const model = {
        //     theme: res.theme,
        //     menu: res.menu,
        //     userName: this.userName
        //   }
        //   this.configurationService.upsertConfigurationForUser(JSON.stringify(model), ConfigurationAction.Create).subscribe({
        //     next: (res: any) => {
        //       if (res) {
        //         this.commonService.setMenu(res);
        //       }
        //     },
        //     error: (error: any) => {
        //       this.router.navigateByUrl("/login", { replaceUrl: true });
        //       console.log(error);
        //     }
        //   })
        // }
      },
      error: (error: any) => {
        if (!this.retry) {
          this.retry = true;
          // this.getConfiguration();
        }
        console.log(error);
      }
    })
  }

  getSidebar() {
    this.configurationService.getSideBar().subscribe({
      next: (res: Menu[]) => {
        if (res) {
          this.commonService.setSidebar(res);
        }
      }
    })
  }

  getProvincesInfo() {
    //call api get provinces
    this.provinceService.getProvinceInfo().subscribe((res: any) => {
      this.commonService.setProvinces(res);
    })
  }

  getCategories() {
    this.categoryService.getCategories().subscribe({
      next: (res: Category[]) => {
        if (res) {
          this.commonService.setCategories(res);
        }
      }
    })
  }

  requestToken() {
    this.requestPermission();
    navigator.serviceWorker.register("firebase-messaging-sw.js", { scope: "/" })
      .then((serviceRegis: ServiceWorkerRegistration) => {
        getToken(this.messaging, {
          vapidKey: environment.vapidKey,
          serviceWorkerRegistration: serviceRegis,
        }).then((token: string) => {
          console.log(token);
          this.registerToken(token);
        })
      })
  }

  requestPermission() {
    Notification.requestPermission().then((per: NotificationPermission) => {
      if (per === 'granted') {
        console.log('NotificationPermission granted');
      } else {
        console.error('NotificationPermission denied');
      }
    })
  }

  registerToken(currentToken: string) {
    this.pushNotiService.subscribeToken(currentToken).subscribe({
      next: (res: BaseResponseModel) => {
        if (!res.isSuccess) {
          console.log('registerToken');
          console.error(res);
        }
      }
    });
  }

  listen() {
    onMessage(this.messaging, {
      next: (payload: MessagePayload) => {
        console.log('Message received. ', payload);

        let body = payload.notification?.body || '';
        let title = payload.notification?.title || '';
        let type = payload.data && payload.data['DataType'] ? payload.data['DataType'] : "";
        let createdAt = payload.data && payload.data['CreatedAt'] ? payload.data['CreatedAt'] : "";
        let data = payload.data && payload.data['Data'] ? payload.data['Data'] : undefined;
        let id = payload.data && payload.data['Id'] ? payload.data['Id'] : '';

        const options: NotificationOptions = {
          body: body,
        };

        let notiModel = new NotificationDto({
          id: id,
          title: title,
          message: body,
          isRead: false,
          createdAt: createdAt,
          type: parseInt(type),
          data: data,
        });

        let url = NotificationDto.getNotiTargetUrl(notiModel);

        this.notifiService.success(notiModel.message, notiModel.title, {}, () => {
          if (url) {
            this.router.navigateByUrl(url);
          }
        });

        this.sharedService.notificationChange();
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        console.log('Done listening to messages');
      }
    })

  }
}
