import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NotificationDto, NotificationRequestModel, SubscribeListModel } from "../interfaces/notification.interface";
import { Observable } from "rxjs";
import { environment } from "@/environments/environment";
import { ResponsePagingModel } from "../interfaces/pagination.interface";
import { BaseResponseModel } from "../interfaces/base-response.interface";

@Injectable({
    providedIn: "root",
})
export class PushNotificationService {
    constructor(private http: HttpClient) { }

    getLastestNotifications(): Observable<ResponsePagingModel<NotificationDto>> {
        let body: NotificationRequestModel = {
            limit: 5,
            offset: 0,
        }
        return this.http.post<ResponsePagingModel<NotificationDto>>(`${environment.baseURL}v1/notification/get-notifications`, body);
    }

    getNotifications(body: NotificationRequestModel): Observable<ResponsePagingModel<NotificationDto>> {
        return this.http.post<ResponsePagingModel<NotificationDto>>(`${environment.baseURL}v1/notification/get-notifications`, body);
    }
    readNotification(notiId: string): Observable<boolean> {
        return this.http.post<boolean>(`${environment.baseURL}v1/notification/read-notification/${notiId}`, null);
    }
    getNotification(notiId: string): Observable<NotificationDto> {
        return this.http.get<NotificationDto>(`${environment.baseURL}v1/notification/get-notification/${notiId}`);
    }

    getSubscribeList(): Observable<SubscribeListModel[]> {
        return this.http.get<SubscribeListModel[]>(`${environment.baseURL}v1/topic/subscribe-list`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    updateSubscribeList(model: string[]): Observable<BaseResponseModel> {
        return this.http.post<BaseResponseModel>(`${environment.baseURL}v1/topic/subscribe-list`, model, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
    subscribeToken(token: string): Observable<BaseResponseModel> {
        let model = {
            'token': token
        };
        return this.http.post<BaseResponseModel>(`${environment.baseURL}v1/topic/subscribe`, model, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
    getUnreadNotification(): Observable<number> {
        return this.http.get<number>(`${environment.baseURL}v1/notification/count-unread-notification`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
}

